export interface SignUpRequest {
  email: string;
  device?: string;
  fullName: string;
  nid: string;
  password: string;
  phone: string;
  partner?: string | null;
  partnerAdvisor?: string | null;
  codeSentTo?: "email" | "phone";
}

export interface SignUpRequestOptions {
  md5?: boolean;
}

export interface CodeConfirmRequest {
  code: string;
  nid: string;
  device?: string;
}

export interface ResendCodeRequest {
  device: string;
  email: string;
  nickName: string;
  nid: string;
  phone: string;
}

export interface SidebarRegisterProvider {
  signUpRequest: Ref<SignUpRequest | null>;
  setSignUpRequest: (signUpRequest: SignUpRequest | null) => void;
  openSidebarRegister: Ref<boolean>;
  currentStep: any;
  setStep(step: any): void;
}

export const sidebarRegisterProvider = Symbol("sidebarRegisterProvider");
