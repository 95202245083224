<script setup lang="ts">
import {
  type ForgotPasswordDrawerFormRequest,
  type ForgotPasswordDrawerFormResponse,
  forgotPasswordDrawerProvider,
  type ForgotPasswordDrawerProvider,
} from "./protocols";
import { ForgotPasswordStep } from "./ForgotPasswordStep";
import { ConfirmationCodeForgetPasswordStep } from "./ConfirmationCodeForgetPasswordStep";
import ForgotPasswordDrawer from "./ForgotPasswordDrawer.vue";

const steps = {
  ForgotPasswordStep,
  ConfirmationCodeForgetPasswordStep,
};

const confirmationCodeForgetPasswordRequest =
  shallowRef<ForgotPasswordDrawerFormRequest | null>(null);

const currentStep = shallowRef(steps.ForgotPasswordStep);

const forgotPasswordDrawerFormResponse =
  shallowRef<ForgotPasswordDrawerFormResponse | null>(null);

function setStep(step: keyof typeof steps) {
  currentStep.value = steps[step];
}

function setForgotPasswordDrawerFormResponse(
  response: ForgotPasswordDrawerFormResponse | null
) {
  forgotPasswordDrawerFormResponse.value = response;
}

function reset() {
  forgotPasswordDrawerFormResponse.value = null;
  setStep("ForgotPasswordStep");
}

function setConfirmationCodeForgetPasswordRequest(
  request: ForgotPasswordDrawerFormRequest | null
) {
  confirmationCodeForgetPasswordRequest.value = request;
}

provide<ForgotPasswordDrawerProvider>(forgotPasswordDrawerProvider, {
  currentStep,
  setStep,
  setForgotPasswordDrawerFormResponse,
  forgotPasswordDrawerFormResponse,
  confirmationCodeForgetPasswordRequest,
  setConfirmationCodeForgetPasswordRequest,
  reset,
});
</script>
<template>
  <ForgotPasswordDrawer />
</template>
