<script setup lang="ts">
import type { ForgotPasswordDrawerFormRequest } from "../../../protocols";
import type { ForgotPasswordDrawerFormProps } from "./protocols";
import { validationSchema } from "./validation";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { Button } from "@/components/Button";
import { extractNumbers } from "@/utils/string/extract-numbers";

const props = defineProps<ForgotPasswordDrawerFormProps>();

const emit = defineEmits<{
  (
    e: "submit",
    forgotPasswordDrawerFormValues: ForgotPasswordDrawerFormRequest
  ): void;
}>();

const { handleSubmit } = useForm<ForgotPasswordDrawerFormRequest>({
  validationSchema,
});

const onSubmit = handleSubmit((forgotPasswordDrawerFormValues) => {
  const { nid } = forgotPasswordDrawerFormValues;
  emit("submit", { nid: extractNumbers(nid) });
});
</script>
<template>
  <form
    class="flex flex-col gap-y-5 z-50"
    autocomplete="off"
    @submit="onSubmit"
  >
    <FloatingLabelInput
      v-maska
      name="nid"
      label="CPF"
      aria-label="CPF"
      data-maska="[
    '###.###.###-##',
    '##.###.###/####-##'
  ]"
    />
    <Button :loading="props.loading"> ENVIAR </Button>
  </form>
</template>
