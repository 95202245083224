<script setup lang="ts">
import { Icon } from "@iconify/vue";
import AppIconButton from "@/components/AppIconButton.vue";
import { useAppStore } from "@/store/app";

const store = useAppStore();
</script>
<template>
  <div class="flex justify-between items-center z-50">
    <h1 class="font-bold text-xl w-2/3">Entre na sua conta</h1>
    <AppIconButton
      aria-label="close"
      @click="store.setOpenSidebarAutenticate(false)"
    >
      <Icon icon="ic:baseline-close" width="24" height="24" />
    </AppIconButton>
  </div>
</template>
