<script setup lang="ts">
import {
  type ForgotPasswordDrawerFormRequest,
  type ForgotPasswordDrawerProvider,
  forgotPasswordDrawerProvider,
} from "../protocols";
import { ForgotPasswordDrawerHeader } from "./components/ForgotPasswordDrawerHeader";
import { ForgotPasswordDrawerForm } from "./components/ForgotPasswordDrawerForm";
import { forgotPassword } from "@/services/account";

const {
  setStep,
  setForgotPasswordDrawerFormResponse,
  setConfirmationCodeForgetPasswordRequest,
} = inject<ForgotPasswordDrawerProvider>(
  forgotPasswordDrawerProvider
) as ForgotPasswordDrawerProvider;

const mainErrorMessage = ref("");
const loading = ref(false);

async function handleSubmit(
  forgotPasswordDrawerFormValues: ForgotPasswordDrawerFormRequest
) {
  try {
    loading.value = true;
    const httpResponse = await forgotPassword(forgotPasswordDrawerFormValues);
    const { data } = httpResponse.data;
    const codeDeliveryDetails = data;
    setConfirmationCodeForgetPasswordRequest(forgotPasswordDrawerFormValues);
    setForgotPasswordDrawerFormResponse(codeDeliveryDetails);
    setStep("ConfirmationCodeForgetPasswordStep");
  } catch (error: any) {
    mainErrorMessage.value = error.message;
  } finally {
    loading.value = false;
  }
}

watch(
  () => mainErrorMessage.value,
  () => {
    if (mainErrorMessage.value) {
      setTimeout(() => {
        mainErrorMessage.value = "";
      }, 3000);
    }
  }
);
</script>
<template>
  <div class="mb-8">
    <ForgotPasswordDrawerHeader />
  </div>

  <ForgotPasswordDrawerForm :loading="loading" @submit="handleSubmit" />
  <p v-show="mainErrorMessage" class="text-xs text-red-600 text-center mt-4">
    <span class="font-medium">{{ mainErrorMessage }}</span>
  </p>
</template>
