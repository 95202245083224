<script setup lang="ts">
import {
  type ForgotPasswordDrawerProvider,
  forgotPasswordDrawerProvider,
} from "../../../protocols";

const { forgotPasswordDrawerFormResponse } =
  inject<ForgotPasswordDrawerProvider>(
    forgotPasswordDrawerProvider
  ) as ForgotPasswordDrawerProvider;
</script>
<template>
  <div class="flex justify-between items-center">
    <h1 class="font-bold text-xl w-2/2">
      {{
        `Confirme o código enviado para o email ${forgotPasswordDrawerFormResponse?.CodeDeliveryDetails.Destination}`
      }}
    </h1>
  </div>
</template>
