export const useLandingHead = () => {
  const env = useRuntimeConfig();

  const metaContent = [
    {
      name: "description",
      content:
        "Somos o maior ecossistema de ativos reais da América Latina, originamos mais de R$ 1 Bi para mais de 72.000 investidores em 10 países.",
    },
    {
      name: "og:title",
      content:
        "Hurst Capital - Maior plataforma de Ativos Reais da América Latina.",
    },
    {
      name: "og:type",
      content: "website",
    },
  ];

  const noScriptContent = [
    {
      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${env.public.googleTagManagerId}"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
    },
  ];

  useHead({
    title:
      "Hurst Capital - Maior plataforma de Ativos Reais da América Latina.",
    meta: metaContent,
    noscript: noScriptContent,
    htmlAttrs: {
      lang: "pt-BR",
    },
  });
};
