<script setup lang="ts">
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";

const store = useAppStore();
const auth = useAuthStore();

const redirectToInvestorArea = useRedirectToInvestorArea();

interface AppMobileNavProps {
  open: boolean;
}
withDefaults(defineProps<AppMobileNavProps>(), {
  open: false,
});

const isSubMenuOpen = ref(false);
const emits = defineEmits(["close-menu"]);
const toggleSubMenu = () => {
  isSubMenuOpen.value = !isSubMenuOpen.value;
};
function close() {
  isSubMenuOpen.value = false;
}
const closeMenu = () => {
  emits("close-menu");
};
</script>
<template>
  <nav
    :class="`fixed min-h-[calc(100vh-84px)] left-0 top-[84px] w-screen bg-dark-slate-gray px-4 md:px-10 xl:px-20 z-10 xl:hidden ${
      open ? 'block' : 'hidden'
    }`"
  >
    <div>
      <ul>
        <li
          v-on-click-outside="close"
          class="group cursor-pointer text-base font-medium border-b flex justify-between items-center relative z-10 hover:text-primary-600"
          @click="toggleSubMenu"
        >
          <div class="relative flex items-center gap-x-[8px] p-4">
            Sobre a Hurst
            <Icon name="ic:round-keyboard-arrow-down" size="20" />
          </div>
        </li>
        <div
          :class="`bg-black rounded-sm mt-[2px] h-auto w-full ${
            isSubMenuOpen ? 'block' : 'hidden'
          }`"
          @click="closeMenu"
        >
          <ul class="py-0">
            <li class="border-b">
              <button class="w-full">
                <NuxtLink
                  to="/quem-somos"
                  class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-6"
                >
                  Quem somos
                </NuxtLink>
              </button>
            </li>
            <li class="border-b">
              <button class="w-full">
                <NuxtLink
                  to="/cases-de-sucesso"
                  class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-6"
                >
                  Cases de Sucesso
                </NuxtLink>
              </button>
            </li>
            <li class="border-b">
              <button class="w-full">
                <NuxtLink
                  to="/quem-somos#trabalhe-conosco"
                  class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-6"
                >
                  Trabalhe Conosco
                </NuxtLink>
              </button>
            </li>
          </ul>
        </div>
        <li class="border-b flex justify-between items-center">
          <button class="w-full" @click="closeMenu">
            <NuxtLink
              to="/oportunidades"
              class="flex items-center gap-x-[8px] p-4 w-full hover:text-primary-600"
            >
              Oportunidades
            </NuxtLink>
          </button>
        </li>
      </ul>
    </div>
    <div class="fixed bottom-0 gap-4 md:hidden w-full pr-8 pb-6">
      <template v-if="!auth.isAuthenticated">
        <span class="grid grid-cols-1 min-[450px]:grid-cols-2 gap-5">
          <button
            class="border rounded-lg border-white py-[10px] px-5 font-normal hover:bg-primary hover:text-black hover:border-primary w-full"
            @click="() => store.setOpenSidebarAutenticate(true)"
          >
            Entrar
          </button>
          <button
            class="rounded-lg py-[10px] px-5 text-black bg-primary hover:bg-[#FFF] font-medium w-full"
            @click="() => store.setOpenSidebarRegister(true)"
          >
            Seja um investidor
          </button>
        </span>
      </template>
      <template v-else>
        <span class="grid grid-cols-1">
          <button
            class="rounded-lg py-[10px] px-5 text-black bg-primary hover:bg-[#FFF] font-medium justify-center items-center min-[500px]:mx-28"
            @click="redirectToInvestorArea"
          >
            Área do investidor
          </button>
        </span>
      </template>
    </div>
  </nav>
</template>
