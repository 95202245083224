<script setup lang="ts">
import {
  type ConfirmationCodeForgetPasswordRequest,
  type ForgotPasswordDrawerProvider,
  forgotPasswordDrawerProvider,
} from "../protocols";
import { ConfirmationCodeForgetPasswordHeader } from "./components/ConfirmationCodeForgetPasswordHeader";
import { ConfirmationCodeForgetPasswordForm } from "./components/ConfirmationCodeForgetPasswordForm";
import { confirmForgotPassword } from "@/services/account";
import { useAppStore } from "@/store/app";

const store = useAppStore();
const mainErrorMessage = ref("");
const successMessage = ref("");
const loading = ref(false);

async function handleSubmit(payload: ConfirmationCodeForgetPasswordRequest) {
  try {
    loading.value = true;
    await confirmForgotPassword(payload);
    successMessage.value = "Senha alterada com sucesso!";
    setTimeout(() => {
      store.setOpenForgotPasswordDrawer(false);
      store.setOpenSidebarAutenticate(true);
    }, 3000);
  } catch (error: any) {
    mainErrorMessage.value = error.message;
  } finally {
    loading.value = false;
  }
}

const { forgotPasswordDrawerFormResponse, setStep } =
  inject<ForgotPasswordDrawerProvider>(
    forgotPasswordDrawerProvider
  ) as ForgotPasswordDrawerProvider;

onBeforeMount(() => {
  if (!forgotPasswordDrawerFormResponse.value) {
    setStep("ForgotPasswordStep");
  }
});
</script>
<template>
  <div class="mb-8">
    <ConfirmationCodeForgetPasswordHeader />
  </div>
  <ConfirmationCodeForgetPasswordForm
    :loading="loading"
    @submit="handleSubmit"
  />
  <p v-show="mainErrorMessage" class="text-xs text-red-600 text-center mt-4">
    <span class="font-medium">{{ mainErrorMessage }}</span>
  </p>
  <p v-show="successMessage" class="text-xs text-green-600 text-center mt-4">
    <span class="font-medium">{{ successMessage }}</span>
  </p>
</template>
