<script setup lang="ts">
import type { SignUpRequest } from "../../../protocols";
import { useCheckEmailRepeated } from "./composables/useCheckEmailRepeated";
import { useCheckNidRepeated } from "./composables/useCheckNidRepeated";
import { useCheckPhoneRepeated } from "./composables/useCheckPhoneRepeated";
import {
  SignUpStepFormLabels,
  type SignUpStepFormProps,
  type SignUpStepFormValues,
} from "./protocols";
import { makeNid, makePhone, usePartner } from "./utils";
import { validationSchema } from "./validation";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { Checkbox } from "@/components/forms/Checkbox";
import { Button } from "@/components/Button";

const props = defineProps<SignUpStepFormProps>();

const emit = defineEmits<{
  (e: "submit", signUpRequest: SignUpRequest): void;
}>();

const { handleSubmit } = useForm<SignUpStepFormValues>({
  validationSchema,
});

const { emailRepeatedError, emailRepeatedLoading } = useCheckEmailRepeated();
const { phoneRepeatedError, phoneRepeatedLoading } = useCheckPhoneRepeated();
const { nidRepeatedError, nidRepeatedLoading } = useCheckNidRepeated();

const onSubmit = handleSubmit((signUpStepFormValues) => {
  if (
    emailRepeatedLoading.value ||
    phoneRepeatedLoading.value ||
    nidRepeatedLoading.value ||
    emailRepeatedError.value ||
    phoneRepeatedError.value ||
    nidRepeatedError.value
  ) {
    return;
  }

  const { fullName, cpfOrCnpj, phone, email, password } = signUpStepFormValues;

  const payload: SignUpRequest = {
    fullName,
    nid: makeNid(cpfOrCnpj),
    phone: makePhone(phone),
    email,
    password,
    partner: usePartner(),
  };

  emit("submit", payload);
});
</script>

<template>
  <form class="flex flex-col z-50" autocomplete="off" @submit="onSubmit">
    <div class="flex flex-col gap-y-5">
      <FloatingLabelInput
        :label="SignUpStepFormLabels.fullName"
        name="fullName"
        aria-label="Nome"
      />
      <FloatingLabelInput
        v-maska
        :label="SignUpStepFormLabels.cpfOrCnpj"
        aria-label="CPF ou CNPJ"
        name="cpfOrCnpj"
        data-maska="###.###.###-##"
        :loading="nidRepeatedLoading"
        :custom-error="nidRepeatedError"
      />
      <FloatingLabelInput
        :label="SignUpStepFormLabels.phone"
        aria-label="Telefone"
        name="phone"
        :loading="phoneRepeatedLoading"
        :custom-error="phoneRepeatedError"
        input-type="phone"
      />
      <FloatingLabelInput
        :label="SignUpStepFormLabels.email"
        name="email"
        :loading="emailRepeatedLoading"
        :custom-error="emailRepeatedError"
      />
      <FloatingLabelInput
        :label="SignUpStepFormLabels.password"
        autocomplete="new-password"
        aria-label="Senha"
        name="password"
        type="password"
      />
      <FloatingLabelInput
        :label="SignUpStepFormLabels.passwordConfirmation"
        autocomplete="new-password"
        aria-label="Confirmação da senha"
        name="passwordConfirmation"
        type="password"
      />
      <Checkbox name="terms">
        <template #label>
          <label
            for="terms"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Li e concordo com os
            <NuxtLink to="/termos-de-uso" class="underline">
              Termos de Uso
            </NuxtLink>
            e
            <NuxtLink to="/politica-de-privacidade" class="underline">
              Política de Privacidade
            </NuxtLink>
          </label>
        </template>
      </Checkbox>
      <Button :loading="props.loading"> Continuar </Button>
    </div>
  </form>
</template>
