import { AxiosError } from "axios";
import { checkPhoneUsed } from "@/services/account";

export const useCheckPhoneRepeated = () => {
  const { value: phone } = useField<string>("phone");
  const phoneRepeatedLoading = ref(false);
  const phoneRepeatedError = ref<string>();

  const checkPhoneField = () => {
    if (phone.value.replace(/\D/g, "").length < 11) {
      phoneRepeatedError.value = undefined;
      return;
    }

    phoneRepeatedLoading.value = true;
    checkPhoneUsed({ phone: phone.value })
      .then(() => {
        phoneRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response?.status === 400 &&
          error.response?.data?.data?.code === "PhoneAlreadyExists"
        ) {
          phoneRepeatedError.value = "Este telefone já está em uso";
        }
      })
      .finally(() => {
        phoneRepeatedLoading.value = false;
      });
  };

  const debouncedCheckPhone = useDebounceFn(checkPhoneField, 1000);
  watch(phone, debouncedCheckPhone);

  return { phoneRepeatedError, phoneRepeatedLoading };
};
