import { toTypedSchema } from "@vee-validate/yup";
import { boolean, object, string, ref as yupRef } from "yup";
import { checkCpfAndCnpj } from "@/utils/string/check-cpf-and-cnpj";
import { isPhoneValid } from "@/utils/string/is-phone-valid";

const requiredMessage = "Campo obrigatório";

const getCharacterValidationError = (str: string) => {
  return `Sua senha deve ter pelo menos 1 caractere ${str}`;
};

export const validationSchema = toTypedSchema(
  object({
    fullName: string().required(requiredMessage).trim(),
    cpfOrCnpj: string()
      .test("check-cpf-and-cnpj", "CPF inválido", (value) =>
        checkCpfAndCnpj(value as string)
      )
      .required(requiredMessage),
    phone: string()
      .test("is-phone-valid", "Telefone inválido", (value) => {
        return isPhoneValid(value);
      })
      .required(requiredMessage)
      .trim()
      .default("55"),
    email: string().email("E-mail inválido").required(requiredMessage).trim(),
    password: string()
      .required(requiredMessage)
      .min(8, "A senha deve ter pelo menos 8 caracteres")
      .matches(/[0-9]/, getCharacterValidationError("Numérico"))
      .matches(/[a-z]/, getCharacterValidationError("Minúsculo"))
      .matches(/[A-Z]/, getCharacterValidationError("Maiúsculo")),
    passwordConfirmation: string()
      .required(requiredMessage)
      .oneOf([yupRef("password")], "As senhas não correspondem"),
    terms: boolean().required(requiredMessage),
  })
);
