import { AxiosError } from "axios";
import { checkNid } from "@/services/account";

export const useCheckNidRepeated = () => {
  const { value: nid } = useField<string>("cpfOrCnpj");
  const nidRepeatedLoading = ref(false);
  const nidRepeatedError = ref<string>();

  const checkNidField = () => {
    if (nid.value.replace(/\D/g, "").length < 11) {
      nidRepeatedError.value = undefined;
      return;
    }

    nidRepeatedLoading.value = true;
    checkNid({ nid: nid.value })
      .then(() => {
        nidRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response?.status === 400 &&
          error.response?.data?.data?.code === "NidAlreadyExists"
        ) {
          nidRepeatedError.value = "Este CPF/CNPJ já está em uso";
        }
      })
      .finally(() => {
        nidRepeatedLoading.value = false;
      });
  };

  const debouncedCheckNid = useDebounceFn(checkNidField, 1000);
  watch(nid, debouncedCheckNid);

  return { nidRepeatedError, nidRepeatedLoading };
};
