import { object, string, ref as yupRef } from "yup";
import { naiveCheckCpfAndCnpj } from "@/utils/string/check-cpf-and-cnpj";

const requiredMessage = "Campo obrigatório";
const getCharacterValidationError = (str: string) => {
  return `Sua senha deve ter pelo menos 1 caractere ${str}`;
};

export const validationSchema = object({
  nid: string()
    .test("check-cpf-and-cnpj", "CPF inválido", (value) =>
      naiveCheckCpfAndCnpj(value as string)
    )
    .required(requiredMessage)
    .trim(),
  password: string()
    .required(requiredMessage)
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(/[0-9]/, getCharacterValidationError("Numérico"))
    .matches(/[a-z]/, getCharacterValidationError("Minúsculo"))
    .matches(/[A-Z]/, getCharacterValidationError("Maiúsculo")),
  passwordConfirmation: string()
    .required(requiredMessage)
    .oneOf([yupRef("password")], "As senhas não correspondem"),
  code: string().required(requiredMessage),
});
