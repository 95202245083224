import { object, string } from "yup";
import { naiveCheckCpfAndCnpj } from "@/utils/string/check-cpf-and-cnpj";

const requiredMessage = "Campo obrigatório";

export const validationSchema = object({
  nid: string()
    .test("check-cpf-and-cnpj", "CPF inválido", (value) =>
      naiveCheckCpfAndCnpj(value as string)
    )
    .required(requiredMessage)
    .trim(),
});
