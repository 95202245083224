<template>
  <nav class="hidden xl:block">
    <ul class="flex gap-x-[30px] font-poppins">
      <li class="group relative cursor-pointer text-base font-medium">
        <span class="flex items-center gap-x-[8px] hover:text-primary-600">
          Sobre a Hurst
          <Icon name="ic:round-keyboard-arrow-down" size="20" />
        </span>
        <div
          class="absolute z-0 bg-black rounded-lg min-w-[calc(100%+16px)] invisible group-hover:visible"
        >
          <ul class="py-2">
            <li>
              <NuxtLink
                to="/quem-somos"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                Quem somos
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/cases-de-sucesso"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                Cases de Sucesso
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/quem-somos/#trabalhe-conosco"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                Trabalhe Conosco
              </NuxtLink>

              <NuxtLink
                to="https://blog.hurst.capital"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                Blog
              </NuxtLink>
            </li>
          </ul>
        </div>
      </li>
      <li class="text-base font-semibold">
        <NuxtLink
          to="/oportunidades"
          class="flex items-center gap-x-[8px] font-medium hover:text-primary-600"
        >
          Oportunidades
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>
