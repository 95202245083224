<script setup lang="ts">
import { onKeyStroke } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import {
  type ForgotPasswordDrawerProvider,
  forgotPasswordDrawerProvider,
} from "./protocols";
import { useAppStore } from "@/store/app";

const { currentStep, reset } = inject<ForgotPasswordDrawerProvider>(
  forgotPasswordDrawerProvider
) as ForgotPasswordDrawerProvider;

const store = useAppStore();

function close() {
  reset();
  store.setOpenForgotPasswordDrawer(false);
}

watch(
  () => store.openForgotPasswordDrawer,
  () => {
    if (window) {
      if (store.openForgotPasswordDrawer) {
        window.document.body.classList.add("overflow-hidden");
      } else {
        window.document.body.classList.remove("overflow-hidden");
      }
    }
  }
);

onBeforeUnmount(() => {
  if (window) {
    window.document.body.classList.remove("overflow-hidden");
  }
});

onKeyStroke("Escape", (e) => {
  e.preventDefault();
  close();
});
</script>
<template>
  <div
    v-on-click-outside="close"
    :class="`fixed h-full bg-white w-[360px] top-0 p-8 ease-in-out duration-300 z-50 ${
      store.openForgotPasswordDrawer ? 'right-0' : 'right-0 translate-x-[100%]'
    }`"
  >
    <component :is="currentStep" />
  </div>
</template>
