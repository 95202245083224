export interface ConfirmationCodeForgetPasswordRequest {
  code: string;
  password: string;
  nid: string;
  passwordConfirmation?: string;
}
export interface ForgotPasswordDrawerFormRequest {
  nid: string;
}

export interface ForgotPasswordDrawerFormResponse {
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
}

export const forgotPasswordDrawerProvider = Symbol(
  "forgotPasswordDrawerProvider"
);

export interface ForgotPasswordDrawerProvider {
  reset: () => void;
  forgotPasswordDrawerFormResponse: Ref<ForgotPasswordDrawerFormResponse | null>;
  setForgotPasswordDrawerFormResponse: (
    response: ForgotPasswordDrawerFormResponse | null
  ) => void;
  currentStep: any;
  confirmationCodeForgetPasswordRequest: Ref<ForgotPasswordDrawerFormRequest | null>;
  setConfirmationCodeForgetPasswordRequest: (
    response: ForgotPasswordDrawerFormRequest | null
  ) => void;
  setStep(step: any): void;
}
