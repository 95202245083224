<script setup lang="ts">
import {
  type ConfirmationCodeForgetPasswordRequest,
  type ForgotPasswordDrawerProvider,
  forgotPasswordDrawerProvider,
} from "../../../protocols";
import { ConfirmationCodeForgetPasswordFormLabels } from "./protocols";
import { validationSchema } from "./validation";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { Button } from "@/components/Button";
import { extractNumbers } from "@/utils/string/extract-numbers";
import { forgotPasswordResendCodeEmail } from "@/services/account";

const emit = defineEmits<{
  (
    e: "submit",
    forgotPasswordDrawerFormValues: ConfirmationCodeForgetPasswordRequest
  ): void;
}>();

const { handleSubmit, setValues } =
  useForm<ConfirmationCodeForgetPasswordRequest>({
    validationSchema,
  });

const { value: nidField } = useField<string>("nid");

const { confirmationCodeForgetPasswordRequest, reset } =
  inject<ForgotPasswordDrawerProvider>(
    forgotPasswordDrawerProvider
  ) as ForgotPasswordDrawerProvider;

const { countdownCounter, countdownOver, restartCountdown } = useCountdown({
  startWith: 0,
});

const sendCodeViaEmail = async () => {
  const notify = useNotify();
  if (!countdownOver.value) return;

  restartCountdown();

  try {
    await forgotPasswordResendCodeEmail({
      platform: "Plataforma Hurst",
      nid: nidField.value?.replace?.(/\D/g, "") ?? "",
      device: navigator.userAgent,
    });
    notify("Código enviado com sucesso", { type: "success" });
  } catch (e) {
    notify("Não foi possível enviar o código", { type: "error" });
  }
};

const onSubmit = handleSubmit((confirmationCodeForgetPasswordRequest) => {
  const { nid, ...rest } = confirmationCodeForgetPasswordRequest;
  delete rest.passwordConfirmation;
  emit("submit", { nid: extractNumbers(nid), ...rest });
});

onMounted(() => {
  if (!confirmationCodeForgetPasswordRequest.value) {
    reset();
  }
  setValues({
    nid: confirmationCodeForgetPasswordRequest.value!.nid as string,
  });
});
</script>
<template>
  <form class="flex flex-col gap-y-5" autocomplete="off" @submit="onSubmit">
    <FloatingLabelInput
      v-maska
      name="nid"
      :label="ConfirmationCodeForgetPasswordFormLabels.nid"
      aria-label="CPF"
      data-maska="[
    '###.###.###-##',
    '##.###.###/####-##'
  ]"
    />
    <FloatingLabelInput
      name="code"
      aria-label="Código"
      :label="ConfirmationCodeForgetPasswordFormLabels.code"
    />
    <FloatingLabelInput
      name="password"
      :label="ConfirmationCodeForgetPasswordFormLabels.newPassword"
      aria-label="Nova senha"
      type="password"
    />
    <FloatingLabelInput
      name="passwordConfirmation"
      :label="ConfirmationCodeForgetPasswordFormLabels.passwordConfirmation"
      aria-label="Confirmação da senha"
      type="password"
    />

    <button
      v-if="countdownOver"
      type="button"
      class="underline"
      @click="sendCodeViaEmail()"
    >
      Enviar código via email
    </button>
    <p v-else class="text-center">
      Enviar código via email ({{ countdownCounter }})
    </p>

    <Button> ENVIAR </Button>
  </form>
</template>
