import { AxiosError } from "axios";
import { checkEmail } from "@/services/account";

export const useCheckEmailRepeated = () => {
  const { value: email } = useField<string>("email");
  const emailRepeatedLoading = ref(false);
  const emailRepeatedError = ref<string>();

  const checkEmailField = () => {
    emailRepeatedLoading.value = true;
    checkEmail({ email: email.value })
      .then(() => {
        emailRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response?.status === 400 &&
          error.response?.data?.data?.code === "EmailAlreadyExists"
        ) {
          emailRepeatedError.value = "Este e-mail já está em uso";
        }
      })
      .finally(() => {
        emailRepeatedLoading.value = false;
      });
  };

  const debouncedCheckEmail = useDebounceFn(checkEmailField, 1000);
  watch(email, debouncedCheckEmail);

  return { emailRepeatedError, emailRepeatedLoading };
};
