<script setup lang="ts">
import {
  type SidebarRegisterProvider,
  sidebarRegisterProvider,
} from "../protocols";
import { ConfirmCodeStepHeader } from "./components/ConfirmCodeStepHeader";
import { ConfirmCodeStepForm } from "./components/ConfirmCodeStepForm";
import { useAuthStore } from "@/store/auth";

const { signUpRequest, setStep } = inject<SidebarRegisterProvider>(
  sidebarRegisterProvider
) as SidebarRegisterProvider;

const { signIn } = useAuthStore();
const sentToPhone = ref<boolean>(signUpRequest.value?.codeSentTo === "phone");

const handleChangeSentToPhone = (): void => {
  sentToPhone.value = false;
};

const redirectToInvestorArea = useRedirectToInvestorArea();

async function handleCodeConfirmation() {
  const httpResponse = await signIn({
    nid: signUpRequest!.value!.nid,
    password: signUpRequest!.value!.password,
  });

  if (httpResponse) {
    redirectToInvestorArea();
  }
}
onMounted(() => {
  if (!signUpRequest) {
    setStep("SignUpStep");
  }
});
</script>
<template>
  <div class="mb-8">
    <ConfirmCodeStepHeader />
  </div>
  <p v-if="sentToPhone" class="font-sans mb-8">
    Confirme o código que foi enviado por SMS para o celular:
    <span class="font-semibold">{{ signUpRequest?.phone }}</span>
  </p>
  <p v-else class="font-sans mb-8">
    Confirme o código que foi enviado para o e-mail:
    <span class="font-semibold">{{ signUpRequest?.email }}</span>
  </p>
  <ConfirmCodeStepForm
    @code-confirmation="handleCodeConfirmation"
    @resend-by-email="handleChangeSentToPhone"
  />
</template>
