export enum SignUpStepFormLabels {
  fullName = "Nome completo",
  cpfOrCnpj = "CPF",
  phone = "Telefone",
  email = "E-mail",
  password = "Senha",
  passwordConfirmation = "Confirme sua senha",
}

export interface SignUpStepFormProps {
  loading?: boolean;
}

export interface SignUpStepFormValues {
  fullName: string;
  cpfOrCnpj: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  terms: boolean;
}
